import React from 'react'
import PagePricing from '../components/pages/Pricing/Pricing'
import Layout from '../Layouts/zh-hk'

const pricingZhHk = ({ location }) => {
  return (
    <Layout location={location}>
      <PagePricing />
    </Layout>
  )
}

export default pricingZhHk
